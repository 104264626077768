@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

#projects-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
}

.h1-content {
    font-family: 'Dosis', sans-serif;
    font-weight: 900;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.item {
    background: white;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    list-style: none;
    margin-bottom: 20px;
}

.item-h2 {
    font-size: 18px;
    padding-top: 10px;
}

.item-p {
    font-size: 20px;
    margin: 10px;
    text-align: justify;
}

.button-go,
.read-me-button {
    background: linear-gradient(to right, darkblue, darkorchid);
    border: none;
    border-radius: 10px;
    justify-content: center;
    font-size: 16px;
    color: white;
    cursor: pointer;
    padding: 5px 15px;
    margin: 5px;
    text-decoration: none;
    margin-top: 10px;
}

.button-go:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
        margin-left: 5%;
        margin-right: 5%;
    }

    .button-go,
    .read-me-button {
        display: block;
        margin: 10px auto;
        width: 50%;
    }
}