.contact-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 20px;
}

.contact-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  margin: 0 auto;
    margin-bottom: 5%;
}

.contact-item {
  width: 100%;
  background: white;
  border-radius: 30px;
  padding: 20px;
}

.contact-item-text {
  font-size: 17px;
  text-align: center;
  padding: 5px;
  margin: 0;
  line-height: 1.5;
}

.contact-item-title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 768px) {
  .contact-container {
    width: 100%;
  }
}