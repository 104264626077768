.skill-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
}

.skills-container {
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    text-align: center;
    margin: 0 auto;
    justify-content: space-between;
    list-style: none;
}

.skill-item {
    background: white;
    border-radius: 30px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    flex: 1;
    max-width: calc(50% - 7.5px);
    padding: 20px;
}

.skills-item-p {
    font-size: 17px;
    text-align: justify;
    padding: 10px;
}

.item-h2, .item-h2-soft {
    font-size: 30px;
}

.item-h2-soft {
    padding-top: 20px;
}

.h1-skills-content {
    font-family: 'Dosis', sans-serif;
    font-weight: 900;
    text-align: center;
    font-size: 85px;
    padding-top: 40px;
}

.skill-box-new {
    padding: 5px 5px;
    background: linear-gradient(to right, darkblue, darkorchid);
    border-radius: 10px;
    font-size: 20px;
    margin-top: 7px;
    color: white;
}

.skills-list-new {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .skills-container {
        flex-direction: column;
    }

    .skill-item {
        max-width: 100%;
    }

        .skill-box-new {
            font-size: 15px;
            padding: 5px 5px;
        }
}