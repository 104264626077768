.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #ECECEC;
}

.fade-out {
    opacity: 0;
    transition: opacity 1.1s;
}

.h1-lp {
    font-size: 80px;
}

.enter-button {
    background: linear-gradient(to right, darkblue, darkorchid);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    margin: 10px;
    outline: none;
    box-shadow: none;
    border-color: transparent;
}

.enter-button:hover {
    transform: scale(1.1);
}



@media (max-width: 768px) {
    .h1-lp {
        font-size: 40px;
        margin-top: -20vh;
    }

    .enter-button {
        margin-top: 5vh;
    }
}