@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap');

.full-page {
  position: relative;
  overflow: hidden;
  font-family: 'Dosis', sans-serif, Arial, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  background: #ECECEC;
}

.section {
  width: 100%;
    min-height: 100vh;
  position: relative;
}

.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20.1px;
  overflow: hidden;

}

.test-h1 {
  font-family: 'Dosis', sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 85px;
}

.test-h1-skills {
  font-family: 'Dosis', sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 85px;
}

.test-h1-contact {
  font-family: 'Dosis', sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 85px;
}

.picture {
  height: 230px;
  width: 200px;
  border-radius: 20%;
  border: 5px solid black;
}

.home-content {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 7%;
  text-align: center;
  border-radius: 10px;
}

.home-h1 {
  font-size: 85px;
font-family: 'Dosis', sans-serif;
  font-weight: 900;
}

.home-sub{
    font-size: 50px;
      font-family: 'Dosis', sans-serif;
      font-weight: 500;
}

.home-p {
  font-size: 30px;
font-family: 'Dosis', sans-serif;
  font-weight: 400;
}

#section2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-me-h1 {
font-family: 'Dosis', sans-serif;
  font-weight: 900;

}

.about-section-title {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  border: 0.5px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;

}

.about-section-subtitle {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  padding: 10px;
  border: 1px solid black;
font-family: 'Dosis', sans-serif;  
font-weight: 500;
}

.sm-profile {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 30%;
  margin-top: 15px;
  transition: transform 0.1s ease;
}

.email-profile {
  width: 55px;
  height: 55px;
  margin: 5px;
  margin-top: 15px;
  border-radius: 35%;
  transition: transform 0.1s ease;
}

h2 {
  color: black;
}

.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 2px solid black;
  height: 100px;
  width: 50px;
  position: relative;
}

.move {
  position: absolute;
  background-color: black;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 1.5s linear infinite;
}

@media (max-width: 768px) {
  .home-content {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
  }

    .home-h1 {
      font-family: 'Dosis', sans-serif;
      font-weight: 900;
      text-align: center;
      font-size: 50px;
      margin-top: 20%;
    }

    .home-sub {
      font-size: 35px;
      margin-top: 10%;
      margin-bottom: 5%;
    }

        .sm-profile, .email-profile {
          margin-bottom: 10%;
        }

    .mouse {
      margin: 0 auto;
      display: block;
      border-radius: 50px;
      border: 2px solid black;
      height: 100px;
      width: 50px;
      position: relative;
    }

.move {
  position: absolute;
  background-color: black;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-25%);
  animation: move 1s linear infinite;
}

  .test-h1,
  .test-h1-skills,
  .test-h1-contact {
    font-size: 60px;
  }

  .picture {
    max-width: 80%;
  }

  .section-content {
    padding: 10px;
}

  .full-page {
    background: #ECECEC;
}

  .sm-profile {
    margin-top: 1px;
        }
    
  .email-profile {
    margin-top: 1px;
        }
               
}

h2 {
  color: black;
  font-weight: 200;
  font-size: 16px;
}



@keyframes move {
  0% {
    transform: translate(-50%, 50px);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, 25px);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 1px);
    opacity: 0;
  }
}